import React from "react";
import "../styles/Patient.css";
import Landing from "./Landing";
export default function Patient({ tokens }) {
	// console.log(tokens);
	return (
		<div>
			<Landing title={tokens.patient_site} learn_more={tokens.learn_more} />
			<main id="main">
				<div className="card">
					{/* Intro */}
					<div className="card-info">
						<h2>{tokens.cataract}</h2>
						<p>
							{tokens.intro} <sup>[1]</sup>:
						</p>
						<p>{tokens.aging}</p>
					</div>
					<div className="card-img">
						<p>{tokens.cross_section_label}</p>
						<div>
							<img
								className="cross_section_diagram cross_section"
								src={tokens.diagram}
								alt="cross-section of human eye diagram"
							/>
						</div>
					</div>
				</div>
				{/* My Cataract */}
				<div className="card">
					<div className="card-img">
						<img
							className="cross_section_diagram"
							src={"/images/stock-eye.jpg"}
							alt="Stock Eye"
						/>

						<a
							className="reference"
							target={"_blank"}
							rel={"noreferrer"}
							href="https://www.pexels.com/photo/brown-human-eye-946727/ "
						>
							{tokens.image_reference}
						</a>
					</div>
					<div className="card-info">
						<h2>{tokens.my_cataract_question}</h2>
						<p>{tokens.my_cataract_info1}</p>
						<p>{tokens.my_cataract_info2}</p>
					</div>
				</div>
				{/* Expected Lifetime */}
				<div className="card">
					<div className="card-info">
						<h2>{tokens.expected_lifetime_question}</h2>
						<p>{tokens.expected_lifetime_info1}</p>
						<p>{tokens.expected_lifetime_info2}</p>
					</div>
					<div className="card-img">
						{/* <p>{tokens.cross_section_label}</p> */}

						<img
							className="cross_section_diagram patient_image"
							src={"/images/follow-up.png"}
							alt="Follow Up"
						/>
						<a
							className="reference"
							target={"_blank"}
							rel={"noreferrer"}
							style={{ color: "black" }}
							href="https://upload.wikimedia.org/wikipedia/commons/c/cb/Schedule_clock.svg "
						>
							{tokens.image_reference}
						</a>
					</div>
				</div>
				{/* safety */}
				<div className="card">
					<div className="card-img">
						{/* <p>{tokens.cross_section_label}</p> */}

						<img
							className="cross_section_diagram patient_image"
							src={"/images/safety.png"}
							alt="Safety"
						/>
						<a
							className="reference"
							target={"_blank"}
							rel={"noreferrer"}
							style={{ color: "black" }}
							href="https://upload.wikimedia.org/wikipedia/commons/b/b7/Approved.png"
						>
							{tokens.image_reference}
						</a>
					</div>

					<div className="card-info">
						<h2>{tokens.device_safety_question}</h2>
						<p>{tokens.device_safety_info}</p>
					</div>
				</div>
				{/* Precautions */}
				<div className="card precautions-card">
					<div className="card-info">
						<h2>{tokens.precautions_question}</h2>
						<p>{tokens.precautions_info}</p>
					</div>
					<div className="card-info before-after">
						<div>
							<ul>
								<p>{tokens.before_surgery_title}</p>
								<li>{tokens.before_surgery_info}</li>
							</ul>
						</div>
						<div>
							<ul>
								<p>{tokens.after_surgery_title}</p>
								<li>{tokens.after_surgery_info.after_surgery1}</li>
								<li>{tokens.after_surgery_info.after_surgery2}</li>
								<li>{tokens.after_surgery_info.after_surgery3}</li>
								<li>{tokens.after_surgery_info.after_surgery4}</li>
								<li>{tokens.after_surgery_info.after_surgery5}</li>
								<li>{tokens.after_surgery_info.after_surgery6}</li>
							</ul>
						</div>
						<p>
							<a
								style={{ color: "white", textDecoration: "none" }}
								href="https://sciphile.org/lessons/blind-spot"
								target={"_blank"}
								rel={"noreferrer"}
							>
								[1] https://sciphile.org/lessons/blind-spot
							</a>{" "}
						</p>
					</div>
				</div>
				<p></p>
			</main>
		</div>
	);
}
