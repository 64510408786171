import React, { useEffect, useState } from "react";
import Select from "../localization/Select";
import { Link, useLocation } from "react-router-dom";
export default function NavBar({
	inEU,
	tokens,
	patient_tokens,
	setTokens,
	lens_tokens,
	setLTokens,
	lTokens,
}) {
	const disclaimer =
		"It appears as though you are accessing this website from outside the European Union. This information is only for residents of the European Union and may not apply to Lenstec products outside the European Union. For information on Lenstec products that are available in your	country of residence, please access our International or United States websites at ";

	const [showDisclaimer, setShowDisclaimer] = useState(!inEU);
	useEffect(() => {
		setShowDisclaimer(!inEU);
	}, [inEU]);

	const path = useLocation();
	const linkStyle = {
		color: "black",
		textDecoration: "none",
		fontSize: "1.5rem",
		margin: "0 30px",
		borderBottom: "3px solid transparent",
	};
	const currentLinkStyle = {
		color: "black",
		textDecoration: "none",
		fontSize: "1.5rem",
		margin: "0 30px",
		borderBottom: "3px solid #0c8b23",
	};

	return (
		<header>
			{/* logo links back to root page */}
			<Link to="/">
				<img
					className="lenstec-logo"
					src="/images/logo-lenstec.png"
					alt="lenstec logo"
				/>
			</Link>
			<div>
				{/* underline the navbar option for the current page */}
				<Link
					style={path.pathname === "/" ? currentLinkStyle : linkStyle}
					to="/"
				>
					{tokens.patient_info}
					{/* Patient Information */}
				</Link>
				<Link
					style={path.pathname === "/models" ? currentLinkStyle : linkStyle}
					to="/models"
				>
					{tokens.lens_info}
					{/* Lens Information */}
				</Link>
			</div>
			<div>
				{/* flag and select component to change language */}
				<img className="flag" src={tokens.flag} alt="Flag" />
				<Select
					patient_tokens={patient_tokens}
					setTokens={setTokens}
					tokens={tokens}
					lTokens={lTokens}
					lens_tokens={lens_tokens}
					setLTokens={setLTokens}
				/>
			</div>
			<div
				style={{
					flexBasis: "100%",
					height: "10px",
					backgroundColor: "white",
				}}
			></div>
			{showDisclaimer && (
				<div style={{ padding: "0 20px" }}>
					<p style={{ color: "red" }}>
						{disclaimer}{" "}
						<a
							style={{ color: "red" }}
							target="_blank"
							rel="noreferrer"
							href="https://www.lenstec.com"
						>
							www.lenstec.com
						</a>
					</p>
					<img
						src="/images/close-icon.svg"
						style={{ width: "20px" }}
						alt="close disclaimer"
						onClick={() => {
							setShowDisclaimer(!showDisclaimer);
						}}
					/>
				</div>
			)}
		</header>
	);
}
