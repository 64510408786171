export const eu_languages = {
	CZ: "cs",
	DE: "de",
	ES: "es",
	FR: "fr",
	GB: "en",
	GR: "el",
	IT: "it",
	NL: "nl",
	PL: "pl",
	PT: "pt",
	SK: "sk",
	RO: "ro",
};
