import React from "react";
import "../styles/Landing.css";
export default function Landing({ title, learn_more }) {
	return (
		<div id="landing" className="landing">
			<h1 className="landing-text">{title}</h1>
			<a href="#learn-more-btn">
				<button id="learn-more-btn" className="learn-more-btn">
					{learn_more || "Learn More"}
				</button>
			</a>
		</div>
	);
}
