import React, { useState, useEffect } from "react";
import { patient_tokens } from "./localization/patient_tokens";
import { lens_tokens } from "./localization/lens_tokens";
import { eu_languages } from "./localization/country-language";
import { eu_countries } from "./localization/eu-countries";
import useGeoLocation from "react-ipgeolocation";
import Footer from "./components/Footer";
import Patient from "./components/Patient";
import Models from "./components/Models";
import NavBar from "./components/NavBar";
import "./styles/App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {
	const location = useGeoLocation();

	useEffect(() => {
		// If the location.country is set then set the tokens to the specific country/language
		if (location.country) {
			// console.log(eu_languages[location.country]);
			if (
				eu_languages[location.country] &&
				eu_languages[location.country] !== undefined
			) {
				patient_tokens.setLanguage(eu_languages[location.country]);
				lens_tokens.setLanguage(eu_languages[location.country]);
				setInEU(true);
			} else {
				//if the location.country is not set then set the language to english
				patient_tokens.setLanguage("en");
				lens_tokens.setLanguage("en");
				setInEU(false);
			}
			if (
				eu_countries[location.country] &&
				eu_countries[location.country] !== undefined
			) {
				setInEU(true);
			} else {
				setInEU(false);
			}
			// update the tokens based on language change
			setTokens((tokens) => ({ ...patient_tokens }));
			setLTokens((ltokens) => ({ ...lens_tokens }));
		}
	}, [location.country]);
	const [tokens, setTokens] = useState(patient_tokens); //storing the patient tokens in state
	const [lTokens, setLTokens] = useState(lens_tokens); //storing the lens tokens in state
	const [inEU, setInEU] = useState(false);
	return location.country ? (
		<div className="App">
			<BrowserRouter>
				{/* navigation bar will stay regardless of route */}
				<NavBar
					inEU={inEU}
					tokens={tokens}
					lTokens={lTokens}
					patient_tokens={patient_tokens}
					lens_tokens={lens_tokens}
					setLTokens={setLTokens}
					setTokens={setTokens}
				/>

				<Routes>
					<Route path="/" element={<Patient tokens={tokens} />} />
					<Route path="/models" element={<Models tokens={lTokens} />} />
					{/* Any incorrect path is routed to the root */}
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</BrowserRouter>
			<Footer />
		</div>
	) : (
		<div></div>
	);
}

export default App;
