import LocalizedStrings from "react-localization";

export const lens_tokens = new LocalizedStrings({
	cs: {
		title: "Informace o čočkách",
		learn_more: "Dozvědět se více",
		subtitle: "Intraokulární čočky Lenstec",
		description:
			"Společnost Lenstec vyrábí různé IOL, které používají zařízení poskytující lékařskou péči na celém světě. Zde naleznete některé z nejlepších vlastností našich IOL:",
		model_heading: "Model",
		appearance_heading: "Vzhled",
		benefits_heading: "Očekávané přínosy v oblasti zraku po zákroku",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: ["Po zákroku dobrý zrak", "Po zákroku jasný zrak"],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Blokuje modré světlo, které tak nedopadá na sítnici"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Snižuje poruchy vidění, např. ozáření, halo efekty a stíny (pomáhá především při řízení v noci)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Zmenšuje velikost řezu"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Po zákroku jasný zrak"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Dobré vidění po operaci na blízko, na střední vzdálenost a na dálku",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Dobré vidění po operaci na blízko, na střední vzdálenost a na dálku",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Zlepšené vidění po operaci na blízko a na dálku"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Po zákroku jasný zrak"],
			},
		],
		note: "POZNÁMKA:",
		bottom_note:
			"Chirurg zvolí model IOL, který se pro daného pacienta nejlépe hodí.",
	},
	de: {
		title: "Informationen über Linsen",
		learn_more: "Mehr erfahren",
		subtitle: "Intraokulare Linsen von Lenstec",
		description:
			"Lenstec stellt unterschiedliche IOLs her, die weltweit von Augenärzten verwendet werden. Hier finden Sie einige der besten Merkmale Ihrer IOLs:",
		model_heading: "Modell",
		appearance_heading: "Aussehen",
		benefits_heading: "Erwartete Sichtvorteile nach der Chirurgie",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Gute Sicht nach der Chirurgie",
					"Scharfe, klare Sicht nach der Chirurgie",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Hindert Blaulicht davon, auf die Retina zu treffen"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Reduziert visuelle Störungen wie Blenden, Halos und Schatten (besonders beim Autofahren im Dunkeln nützlich)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Reduziert die Einschnittgröße"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Scharfe, klare Sicht nach der Chirurgie"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: ["Gute Sicht auf nahe, mittlere und weite Entfernung"],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: ["Gute Sicht auf nahe, mittlere und weite Entfernung"],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: [
					"Verbesserte Nah- und Weitsicht nach dem chirurgischen Eingriff",
				],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Scharfe, klare Sicht nach der Chirurgie"],
			},
		],
		note: "HINWEIS:",
		bottom_note:
			"Der Chirurg wird das IOL-Modell auswählen, das für den jeweiligen Patienten am besten geeignet ist.",
	},
	el: {
		title: "Πληροφορίες για τους φακούς",
		learn_more: "Περισσότερα",
		subtitle: "Ενδοφθάλμιοι φακοί της Lenstec",
		description:
			"Η Lenstec κατασκευάζει διάφορους IOL για χρήση από ιδρύματα υγειονομικής περίθαλψης παγκοσμίως. Μερικά από τα καλύτερα χαρακτηριστικά των ενδοφακών μας:",
		model_heading: "Μοντέλο",
		appearance_heading: "Εμφάνιση",
		benefits_heading:
			"Αναμενόμενα οφέλη στην όραση μετά τη χειρουργική επέμβαση",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Καλή όραση μετά τη χειρουργική επέμβαση",
					"Διαυγή, καθαρή όραση μετά από χειρουργική επέμβαση",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Εμποδίζει το μπλε φως να φτάσει στον αμφιβληστροειδή"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Μειώνει τις οπτικές διαταραχές, όπως λάμψη, άλω και σκιές (ιδιαίτερα χρήσιμος κατά τη διάρκεια της νυχτερινής οδήγησης)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Μειώνει το μέγεθος τομής"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Διαυγή, καθαρή όραση μετά από χειρουργική επέμβαση"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Καλή κοντινή, ενδιάμεση και μακρινή όραση μετά την επέμβαση",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Καλή κοντινή, ενδιάμεση και μακρινή όραση μετά την επέμβαση",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: [
					"Ενισχυμένη κοντινή και μακρινή όραση μετά τη χειρουργική επέμβαση",
				],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Διαυγή, καθαρή όραση μετά από χειρουργική επέμβαση"],
			},
		],
		note: "ΣΗΜΕΙΩΣΗ:",
		bottom_note:
			"Ο χειρουργός θα επιλέξει το μοντέλο του IOL που ταιριάζει καλύτερα στον ασθενή.",
	},
	en: {
		title: "Lens Information",
		learn_more: "Learn More",
		subtitle: "Lenstec Intraocular Lenses",
		description:
			"Lenstec manufactures various IOLs for use by healthcare institutions worldwide. Here are some of the best features of our IOLs:",
		model_heading: "Model",
		appearance_heading: "Appearance",
		benefits_heading: "Expected vision benefits after surgery",
		lens_info: [
			{
				series: "SOFTEC Series",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Good vison after surgery",
					"Crisp, clear vision after surgery",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Blocks blue light from reaching the retina"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Reduces visual disturbances such as glare, halos and shadows (especially helpful during night-time driving)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Reduces incision size"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Crisp, clear vision after surgery"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: ["Good near, intermediate and distance vision after surgery"],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: ["Good near, intermediate and distance vision after surgery"],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Enhanced near and distance vision after surgery"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Crisp, clear vision after surgery"],
			},
		],
		note: "NOTE:",
		bottom_note:
			"The surgeon will choose the IOL model best suited for the patient.",
	},
	es: {
		title: "Información sobre las lentes",
		learn_more: "Más información",
		subtitle: "Las lentes intraoculares de Lenstec",
		description:
			"Lenstec fabrica distintas LIO para ser utilizadas en instituciones sanitarias de todo el mundo. Estas son algunas de las mejores características de nuestras LIO:",
		model_heading: "Modelo",
		appearance_heading: "Aspecto",
		benefits_heading:
			"Beneficios esperados para la visión después de la cirugía",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Buena visión después de la cirugía",
					"Visión clara y nítida después de la cirugía",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Bloquea la luz azul para que no llegue a la retina"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Reduce las alteraciones visuales tales como resplandor, halos y sombras (especialmente útil cuando se conduce durante la noche)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Reduce el tamaño de la incisión"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Visión clara y nítida después de la cirugía"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: ["Buena visión próxima, intermedia y lejana tras la cirugía"],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: ["Buena visión próxima, intermedia y lejana tras la cirugía"],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Mejora de la visión próxima y lejana tras la cirugía"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Visión clara y nítida después de la cirugía"],
			},
		],
		note: "IMPORTANTE:",
		bottom_note:
			"El/la cirujano/a será quien elija el modelo de LIO que sea más apropiado para el paciente.",
	},
	fr: {
		title: "Informations sur les lentilles",
		learn_more: "En savoir plus",
		subtitle: "Lentilles intra-oculaires Lenstec",
		description:
			"Lenstec fabrique plusieurs types de LIO destinées aux établissements de santé du monde entier. Voici quelques-unes des meilleures caractéristiques de nos LIO:",
		model_heading: "Modèle",
		appearance_heading: "Apparence",
		benefits_heading: "Avantages visuels attendus après la chirurgie",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Bonne vision après la chirurgie",
					"Vision nette et claire après la chirurgie",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Empêche la lumière bleue d’atteindre la rétine"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Réduit les perturbations visuelles telles que l’éblouissement, les halos et les ombres (particulièrement utile pendant la conduite nocturne)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Réduit la taille de l’incision"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Vision nette et claire après la chirurgie"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Bonne vision de près, intermédiaire et de loin après l’opération",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Bonne vision de près, intermédiaire et de loin après l’opération",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: [
					"Amélioration de la vision de près et de loin après l’opération",
				],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Vision nette et claire après la chirurgie"],
			},
		],
		note: "REMARQUE:",
		bottom_note:
			"Le chirurgien choisira le modèle de LIO le mieux adapté au patient.",
	},
	it: {
		title: "Informazioni sulle lenti",
		learn_more: "Per saperne di più",
		subtitle: "Lenti intraoculari Lenstec",
		description:
			"Lenstec fabbrica vari tipi di IOL disponibili per l'uso da parte delle istituzioni sanitarie in tutto il mondo. Ecco alcune delle caratteristiche migliori delle nostre lenti intraoculari:",
		model_heading: "Modello",
		appearance_heading: "Aspetto",
		benefits_heading: "Benefit previsti per la visione dopo la chirurgia",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Visione buona dopo la chirurgia",
					"Visione precisa e chiara dopo la chirurgia",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Blocca la luce blu che così non raggiunge la retina"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Riduce i disturbi alla vista come bagliori, aloni e ombre (utile soprattutto durante la guida notturna)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Riduce la grandezza dell'incisione"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Visione precisa e chiara dopo la chirurgia"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Buona visione da vicino, a distanza intermedia e da lontano dopo la chirurgia",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Buona visione da vicino, a distanza intermedia e da lontano dopo la chirurgia",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Visione migliore da vicino e da lontano dopo la chirurgia"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Visione precisa e chiara dopo la chirurgia"],
			},
		],
		note: "NOTA:",
		bottom_note:
			"Il chirurgo sceglie il modello di IOL più idoneo per il paziente.",
	},
	nl: {
		title: "Lensinformatie",
		learn_more: "Meer informatie",
		subtitle: "Intraoculaire lenzen van Lenstec",
		description:
			"Lenstec ontwikkelt verschillende soorten IOL's voor gebruik door zorginstellingen wereldwijd. Hier zijn enkele van de beste kenmerken van onze IOL's:",
		model_heading: "Model",
		appearance_heading: "Uiterlijk",
		benefits_heading: "Verwachte voordelen na operatie",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Goed zicht na operatie",
					"Scherp, helder zicht na operatie",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Voorkomt dat blauw licht het netvlies bereikt"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Beperkt visuele belemmeringen, zoals verblinding, lichtkringen en schaduwen (met name handig voor autorijden in het donker)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Beperkt de grootte van insnede"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Scherp, helder zicht na operatie"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Goed gezichtsvermogen dichtbij, wat verder weg en op afstand na de operatie",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Goed gezichtsvermogen dichtbij, wat verder weg en op afstand na de operatie",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: [
					"Verbeterd gezichtsvermogen dichtbij en veraf na de operatie",
				],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Scherp, helder zicht na operatie"],
			},
		],
		note: "OPMERKING:",
		bottom_note:
			"De chirurg kiest het IOL-model dat het best bij de patiënt past.",
	},
	pl: {
		title: "Informacje dotyczące soczewek",
		learn_more: "Dowiedz się więcej",
		subtitle: "Implanty soczewki wewnątrzgałkowej Lenstec",
		description:
			"Firma Lenstec produkuje różne rodzaje implantów IOL, które są stosowane w instytucjach opieki zdrowotnej na całym świecie. Oto niektóre z najlepszych cech naszych soczewek IOL:",
		model_heading: "Model",
		appearance_heading: "Wygląd",
		benefits_heading: "Oczekiwana poprawa wzroku po operacji",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"dobry wzrok po operacji",
					"ostre, wyraźne widzenie po operacji",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["chroni siatkówkę przed światłem niebieskim"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"ogranicza zaburzenia widzenia takie jak blaski, „efekt halo” oraz cienie (szczególnie przydatne podczas prowadzenia pojazdów nocą)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["zmniejsza wielkość nacięcia"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["ostre, wyraźne widzenie po operacji"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Dobre widzenie w bliży, odległościach pośrednich i dali po zabiegu",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Dobre widzenie w bliży, odległościach pośrednich i dali po zabiegu",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Lepsze widzenie w bliży i dali po zabiegu"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["ostre, wyraźne widzenie po operacji"],
			},
		],
		note: "UWAGA:",
		bottom_note: "Chirurg wybierze model IOL najlepszy dla danego pacjenta.",
	},
	pt: {
		title: "Informação sobre a Lente",
		learn_more: "Saber mais",
		subtitle: "Lentes Intraoculares Lenstec",
		description:
			"A Lenstec fabrica várias LIO para utilização em instituições de cuidados de saúde em todo o mundo. Estas são algumas das melhores funcionalidades das nossas LIO:",
		model_heading: "Modelo",
		appearance_heading: "Aspeto",
		benefits_heading: "Benefícios esperados para a visão após a cirurgia",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Boa visão após a cirurgia",
					"Visão cristalina e límpida após a cirurgia",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Impede que a luz azul chegue à retina"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Reduz as perturbações visuais, tais como reflexos, halos e sombras (especialmente útil para a condução noturna)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Reduz o tamanho da incisão"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Visão cristalina e límpida após a cirurgia"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: ["Boa visão ao perto, intermédia e ao longe após a cirurgia"],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: ["Boa visão ao perto, intermédia e ao longe após a cirurgia"],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Visão melhorada ao perto e ao longe após cirurgia"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Visão cristalina e límpida após a cirurgia"],
			},
		],
		note: "NOTA:",
		bottom_note:
			"O cirurgião irá escolher o modelo de LIO mais adequado ao paciente.",
	},
	ro: {
		title: "Informații despre lentile",
		learn_more: "Aflați mai multe",
		subtitle: "Lentile intraoculare Lenstec",
		description:
			"Lenstec produce diverse lentile intraoculare care să fie utilizate de instituțiile medicale din întreaga lume. Printre cele mai apreciate caracteristici ale lentilelor intraoculare pe care le producem se numără:",
		model_heading: "Model",
		appearance_heading: "Aspect",
		benefits_heading: "Beneficii preconizate asupra vederii după operație",
		lens_info: [
			{
				series: "SERIA SOFTEC",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Vedere îmbunătățită după operație",
					"Vedere clară și precisă după operație",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Blochează lumina albastră pentru a nu ajunge la retină"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Reduce tulburările vizuale, cum ar fi strălucirea, halourile și umbrele (utilă mai ales în timpul condusului pe timp de noapte)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Reduce dimensiunea inciziei"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Vedere clară și precisă după operație"],
			},
			{
				series: "SERIA SBL",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Vedere bună de aproape, intermediară și la distanță după operație",
				],
			},
			{
				series: "SERIA ASTREA",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Vedere bună de aproape, intermediară și la distanță după operație",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: [
					"Vedere îmbunătățită de aproape și la distanță după operație",
				],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Vedere clară și precisă după operație"],
			},
		],
		note: "NOTĂ:",
		bottom_note:
			"Chirurgul va alege modelul cel mai potrivit de lentile intraoculare pentru pacient.",
	},
	sk: {
		title: "Informácie o šošovkách",
		learn_more: "Zistiť viac",
		subtitle: "Vnútroočné šošovky od spoločnosti Lenstec",
		description:
			"Lenstec vyrába rôzne IOL, ktoré používajú zdravotnícke zariadenia na celom svete. Tu sú niektoré z najlepších vlastností našich IOL:",
		model_heading: "Model",
		appearance_heading: "Vzhľad",
		benefits_heading: "Očakávané výsledky po operácii",
		lens_info: [
			{
				series: "SOFTEC IOLs",
				models: ["Softec I", "Softec HD", "Softec HD Click"],
				appearance: "/images/lens/softec-series-1.png",
				benefits: [
					"Dobré videnie po operácii",
					"Ostré, čisté videnie po operácii",
				],
			},
			{
				models: ["Softec HDY"],
				appearance: "/images/lens/softec-hdy.png",
				benefits: ["Zabraňuje modrému svetlu dosiahnuť sietnicu"],
			},
			{
				models: ["Softec HDO"],
				appearance: "/images/lens/softec-hdo.png",
				benefits: [
					"Znižuje rušivé vplyvy ako oslnenie, žiarivý prstenec a tiene (hlavne pociťované počas nočného šoférovania)",
				],
			},
			{
				models: ["Softec HDM"],
				appearance: "/images/lens/softec-hdm.png",
				benefits: ["Zmenšuje veľkosť rezu"],
			},
			{
				series: "SOFTEC HD3",
				models: ["Softec HD3"],
				appearance: "/images/lens/softec-hd3.png",
				benefits: ["Ostré, čisté videnie po operácii"],
			},
			{
				series: "SBL-SERIES",
				models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
				appearance: "/images/lens/sbl-series.png",
				benefits: [
					"Po operácii dobré videnie do blízka, na strednú vzdialenosť a do diaľky",
				],
			},
			{
				series: "ASTREA SERIES",
				models: ["ASTREA", "ASTREA Click"],
				appearance: "/images/lens/astrea-series.png",
				benefits: [
					"Po operácii dobré videnie do blízka, na strednú vzdialenosť a do diaľky",
				],
			},
			{
				series: "TETRAFLEX HD",
				models: ["Tetraflex HD"],
				appearance: "/images/lens/tetraflex-hd.png",
				benefits: ["Po operácii zlepšenie videnia na blízko a do diaľky"],
			},
			{
				series: "SOFTEC HP1",
				models: ["Softec HP1"],
				appearance: "/images/lens/softec-hp1.jpg",
				benefits: ["Ostré, čisté videnie po operácii"],
			},
		],
		note: "POZNÁMKA:",
		bottom_note: "Chirurg vyberie model IOL najvhodnejší pre pacienta.",
	},
});
