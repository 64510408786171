import React from "react";
import Landing from "./Landing";
import LensRow from "./LensRow";
import "../styles/Models.css";
export default function Models({ tokens }) {
	const lensFeatures = [
		{
			series: "SOFTEC Series",
			models: ["Softec I", "Softec HD", "Softec HD Click"],
			appearance: "/images/lens/softec-series-1.png",
			benefits: [
				"Good vison after surgery",
				"Crisp, clear vision after surgery",
			],
		},
		{
			models: ["Softec HDY"],
			appearance: "/images/lens/softec-hdy.png",
			benefits: ["Blocks blue light from reaching the retina"],
		},
		{
			models: ["Softec HDO"],
			appearance: "/images/lens/softec-hdo.png",
			benefits: [
				"Reduces visual disturbances such as glare, halos and shadows (especially helpful during night-time driving)",
			],
		},
		{
			models: ["Softec HDM"],
			appearance: "/images/lens/softec-hdm.png",
			benefits: ["Reduces incision size"],
		},
		{
			series: "SOFTEC HD3",
			models: ["Softec HD3"],
			appearance: "/images/lens/softec-hd3.png",
			benefits: ["Crisp, clear vision after surgery"],
		},
		{
			series: "SBL-SERIES",
			models: ["SBL-3", "SBL-2", "SBL-3 Click", "SBL-2 Click"],
			appearance: "/images/lens/sbl-series.png",
			benefits: ["Good near, intermediate and distance vision after surgery"],
		},
		{
			series: "ASTREA SERIES",
			models: ["ASTREA", "ASTREA Click"],
			appearance: "/images/lens/astrea-series.png",
			benefits: ["Good near, intermediate and distance vision after surgery"],
		},
	];
	// console.log(tokens);

	return (
		<div>
			<Landing title={tokens.title} learn_more={tokens.learn_more} />
			<main id="lens-main" className="lens-main">
				<h2
					style={{
						borderBottom: "3px solid green",
						width: "fit-content",
						margin: "auto",
						textAlign: "center",
					}}
				>
					{tokens.subtitle || "Lenstec Intraocular Lenses"}
				</h2>
				<p>
					{tokens.description ||
						"Lenstec manufactures various IOLs for use by healthcare institutions worldwide. Here are some of the best features of our IOLs:"}
				</p>
				<div className="table">
					<div className="heading">
						<p>{tokens.model_heading}</p>
						<p>{tokens.appearance_heading}</p>
						<p>{tokens.benefits_heading}</p>
					</div>

					{/* generate rows in the chosen or defualt language */}
					<LensRow
						lensFeatures={lensFeatures}
						tokens={
							tokens._props[`${tokens._language}`].lens_info
								? tokens._props[`${tokens._language}`].lens_info
								: tokens._props[`${tokens._defaultLanguage}`].lens_info
						}
					/>
				</div>
				<p>
					<span style={{ textDecoration: "underline", fontWeight: "bold" }}>
						{tokens.note}
					</span>{" "}
					{tokens.bottom_note}
				</p>
			</main>
		</div>
	);
}
