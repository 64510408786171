import LocalizedStrings from "react-localization";

export const patient_tokens = new LocalizedStrings({
	cs: {
		learn_more: "Dozvědět se více",
		patient_site: "Webová stránka informací pro pacienty společnosti Lenstec",
		lens_info: "Informace o čočkách",
		patient_info: "Informácie pre pacienta",
		flag: "/images/czech.gif",
		cataract: "Katarakta",
		image_reference: "Obrázek Reference",
		intro:
			"Oko je tvořeno různými strukturami, díky kterým vidíme. Hlavní dvě struktury, které umožňují vznik ostrého obrazu na sítnici (zadní části oka), jsou rohovka (vnější přední povrch oka) a čočka (umístěna v oku). Níže uvedený nákres ukazuje lidské oko a to, jak se v něm tvoří obraz ",
		cross_section_label: "Průřez Lidským Okem",
		diagram: "/images/eye-cs.png",
		aging:
			"V průběhu stárnutí se přirozená čočka v oku stává tužší a zakalenou. Tento proces může začít okolo 40 či 50 let věku. Této tužší a zakalené čočce se říká katarakta. Může způsobit zhoršení zraku (např. rozmazané, dvojitě vidění) a ztížit provádění každodenních činností, např. čtení nebo řízení.",
		my_cataract_question: "Jak se může katarakta řešit?",
		my_cataract_info1:
			"Nejoblíbenější léčbou katarakty je zákrok, při němž je z postiženého oka katarakta odstraněna. V minulosti se v rámci zákroku pouze odstranila zakalená čočka. Pacient tedy v oku žádnou čočku neměl (takzvaná „afakie“). Vzhledem k velmi špatnému zraku po zákroku musel pacient jako kompenzaci nosit brýle s velmi tlustými skly.",
		my_cataract_info2:
			"Medicína se od té doby velmi vyvinula a nyní se při odstranění přirozené čočky chirurgicky implantuje čočka umělá. Umělá čočka se nazývá intraokulární (nitrooční) čočka („IOL“) a chová se stejně jako přirozená čočka - umožňuje vznik ostrého obrazu na sítnici. Většina pacientů s implantovanou IOL potřebuje pouze brýle na blízko (např. na čtení), záleží to však na celkovém zdravotním stavu pacienta a stavu jeho očí. Možné výsledky léčby s Vámi před zákrokem prodiskutuje Váš oční lékař.",
		expected_lifetime_question:
			"Očekávaná doba životnosti prostředku a potřebné následné sledování",
		expected_lifetime_info1:
			"IOL je permanentní implantát, který má v oku pacienta zůstat po celý život, pokud není potřeba jej z nějakého jiného důvodu odstranit.",
		expected_lifetime_info2:
			"Po implantaci IOL je obvykle potřeba, aby se pacient dostavil na jednu kontrolu hojení oka po zákroku u svého očního lékaře. Pokud se po implantaci objeví jakékoli potíže, pacient se má neodkladně obrátit na svého očního lékaře.",
		device_safety_question: "Bezpečnost prostředku v oku pacienta",
		device_safety_info:
			"U IOL společnosti Lenstec byly provedeny zkoušky biokompatibility v oku a toxicity pro oko a okolní tkáně. Zkoušení proběhlo v souladu s rozličnými mezinárodními normami a výsledky ukázaly, že materiály IOL nejsou pro oko toxické, dráždivé ani senzibilizující.",
		precautions_question:
			"Varování, bezpečnostní opatření nebo opatření, která má provést pacient",
		precautions_info:
			"Oční lékař, který zákrok provede, obvykle před zákrokem pacientovi poskytne pokyny. Další varování a bezpečnostní opatření doporučená společností Lenstec jsou uvedená níže.",
		before_surgery_title: "Před zákrokem",
		before_surgery_info:
			"Poté, kdy lékař aplikuje anestetika, si nemněte oči. Může dojít k poškrábání rohovky, které může v důsledku znecitlivění oka uniknout pozornosti.",
		after_surgery_title: "Po zákroku",
		after_surgery_info: {
			after_surgery1: "Neřiďte. Požádejte někoho, aby vás po zákroku vyzvedl.",
			after_surgery2:
				"Nemněte si oči. Je možné, že vás budou oči několik dní po zákroku v důsledku hojení svědit.",
			after_surgery3:
				"Vyhýbejte se prašnému prostředí. Může způsobit podráždění očí.",
			after_surgery4:
				"Několik dní po zákroku zabraňte kontaktu očí s vodou. Vyhněte se pobytu v moři, bazénu, vaně apod. Teplou žínkou si otřete okolí očí.",
			after_surgery5:
				"Neprovozujte namáhavé činnosti. Nezvedejte těžké předměty ani neprovozujte náročné cvičení.",
			after_surgery6: "Na oko netlačte.",
		},
		placeholder: "",
	},
	de: {
		learn_more: "Mehr erfahren",
		patient_site: "Lenstec-Website mit Patienteninformationen",
		lens_info: "Informationen über Linsen",
		patient_info: "Patienteninformationen",
		flag: "/images/german.gif",
		cataract: "Katarakt",
		image_reference: "Bildreferenz",
		intro:
			"Das Auge besteht aus verschiedenen Strukturen, die die Sicht ermöglichen. Die beiden Hauptstrukturen, die Bilder auf die Retina (Augenrückseite) fokussieren, sind die Hornhaut (die äußere Vorderoberfläche des Auges) und die Linse (im Auge). Die nachstehende Abbildung zeigt das menschliche Auge und wie wir sehen ",
		cross_section_label: "Querschnitt Des Menschlichen Auge",
		diagram: "/images/eye-de.png",
		aging:
			"Im Verlauf des Alterungsprozesses verhärtet sich die natürliche Linse des Auges und wird trübe. Dies kann ab dem Alter von 40 bis 50 Jahren beginnen. Diese verhärtete, getrübte Linse wird Katarakt genannt. Sie kann zu einer Verschlechterung der Sicht (z. B. Unschärfe oder Diplopie) führen und alltägliche Aktivitäten wie Lesen oder Autofahren erschweren.",
		my_cataract_question: "Wie kann mein Katarakt behandelt werden?",
		my_cataract_info1:
			"Die beliebteste Behandlung für einen Katarakt ist eine Chirurgie, bei der der Katarakt aus dem betroffenen Auge entfernt wird. In der Vergangenheit wurde bei einer Kataraktchirurgie nur die trübe Linse entfernt, was bedeutete, dass Patienten nach dem Eingriff keine Linse mehr im Auge hatten (was als „aphakisch“ bezeichnet wurde). Die Patienten benötigen hinterher sehr starke Brillen, um die schlechte Sicht nach der Operation auszugleichen.",
		my_cataract_info2:
			"Seither haben wir viele Fortschritte erzielt und Sie können sich jetzt eine künstliche Linse einpflanzen lassen, die die natürliche Linse ersetzt. Diese künstliche Linse wird als intraokulare Linse („IOL“ bezeichnet. Sie verhält sich wie die natürliche Linse des Auges und fokussiert Bilder auf die Retina. Mit einem IOL-Implantat benötigen die meisten Patienten nur noch Lesebrillen für die Nahsicht (z. B. Lesen). Allerdings hängt das Ergebnis auch von der allgemeinen Gesundheit sowie der Augengesundheit der Patienten ab. Vor einem Eingriff wird Ihr Augenarzt mit Ihnen die möglichen Ergebnisse besprechen.",
		expected_lifetime_question:
			"Erwartete Lebensdauer des Geräts und erforderliche Nachbehandlungen",
		expected_lifetime_info1:
			"Die IOL ist ein permanentes Implantat und soll für die Lebenszeit des Patienten im Auge bleiben, außer sie muss aus anderen Gründen chirurgisch entfernt werden.",
		expected_lifetime_info2:
			"Nach der Implantieren der IOL erfordern Patienten mindestens eine Nachuntersuchung mit dem Augenarzt, um den Heilungsfortschritt des Auges nach der Operation zu verfolgen. Sollten nach der Implantation Notfälle auftreten, sollten sich Patienten unverzüglich an ihren Augenarzt wenden.",
		device_safety_question: "Sicherheit des Geräts im Auge des Patienten",
		device_safety_info:
			"Die IOLs der von Lenstec wurden auf Biokompatibilität im Auge sowie auf Toxizität am Auge und dem umgebenden Gewebe geprüft. Testen erfolgte entsprechend den verschiedenen internationalen Standards und die Ergebnisse zeigten, dass die IOL-Materialien keine toxischen, reizenden oder sensibilisierenden Effekte auf das Auge hatten.",
		precautions_question:
			"Warnungen, Vorsichtsmaßnahmen und vom Patienten zu ergreifende Vorkehrungen",
		precautions_info:
			"Der Augenchirurg wird dem Patienten normalerweise vor dem Eingriff Anweisungen erteilen. Nachfolgend werden andere, von Lenstec empfohlene Warnungen und Hinweise aufgeführt.",
		before_surgery_title: "Vor dem Eingriff",
		before_surgery_info:
			"Reiben Sie Ihre Augen nicht, nachdem der Arzt das Betäubungsmittel angewendet hat. Dies könnte die Hornhaut zerkratzen, was aufgrund der Betäubung nicht bemerkt würde.",
		after_surgery_title: "Nach dem Eingriff",
		after_surgery_info: {
			after_surgery1:
				"Fahren Sie nicht. Lassen Sie sich nach dem Eingriff abholen.",
			after_surgery2:
				"Reiben Sie Ihre Augen nicht. Aufgrund des Heilungsprozesses können Ihre Augen einige Tage nach der Operation jucken.",
			after_surgery3:
				"Vermeiden Sie staubige Bereiche. Dies könnte Ihre Augen reizen.",
			after_surgery4:
				"Setzten Sie Ihre Augen einige Tage nach dem Eingriff keinem Wasser aus. Dazu gehören auch Meerwasser, Schwimmbecken oder Badewannen. Verwenden Sie einen warmen Waschlappen, um vorsichtig um das Auge zu wischen.",
			after_surgery5:
				"Führen Sie keine anstrengenden Aktivitäten durch. Dazu gehört auch Bücken, um schwere Gegenstände zu heben, oder Sport.",
			after_surgery6: "Üben Sie keinen Druck auf das Auge aus.",
		},
		placeholder: "Ausgewählte Sprache",
	},
	el: {
		learn_more: "Περισσότερα",
		patient_site: "Ιστότοπος ενημέρωσης ασθενών Lenstec",
		lens_info: "Πληροφορίες για τους φακούς",
		patient_info: "Πληροφορίες για τους ασθενείς",
		flag: "/images/greece.gif",
		cataract: "Καταρράκτης",
		image_reference: "Αναφορά εικόνας",
		intro:
			"Ο οφθαλμός αποτελείται από διάφορες δομές που μας βοηθούν να βλέπουμε. Οι δύο κύριες δομές που εστιάζουν τις εικόνες στον αμφιβληστροειδή (πίσω μέρος του ματιού), είναι ο κερατοειδής (εξωτερική εμπρόσθια επιφάνεια του οφθαλμού) και ο φακός (εντός του οφθαλμού). Το παρακάτω διάγραμμα παρουσιάζει τον ανθρώπινο οφθαλμό και πώς βλέπουμε ",
		cross_section_label: "Διατομή Του Ανθρώπινου Οφθαλμο",
		diagram: "/images/eye-el.png",
		aging:
			"Καθώς γερνάμε, ο φυσικός φακός του οφθαλμού αρχίζει να σκληραίνει και να θολώνει. Αυτό μπορεί να ξεκινήσει περίπου από την ηλικία των 40 ή 50 ετών. Αυτός ο σκληρυμένος, θολός φακός ονομάζεται καταρράκτης, και μπορεί να προκαλέσει μείωση της όρασης (π.χ. θόλωση, διπλή όραση) και να καταστήσει δύσκολη την εκτέλεση καθημερινών δραστηριοτήτων, όπως η ανάγνωση ή η οδήγηση.",
		my_cataract_question: "Τι μπορεί να γίνει με τον καταρράκτη μου",
		my_cataract_info1:
			"Η πιο δημοφιλής θεραπεία για τον καταρράκτη είναι η χειρουργική επέμβαση για την αφαίρεση του καταρράκτη από τον προσβεβλημένο οφθαλμό. Στο παρελθόν, η χειρουργική επέμβαση καταρράκτη περιλάμβανε μόνο την αφαίρεση του θολού φακού, αφήνοντας τον ασθενή χωρίς φακό στο μάτι (καλείται «αφακικός»). Ο ασθενής έπρεπε στη συνέχεια να φοράει πολύ παχιά γυαλιά για να αντισταθμίσει την κακή όραση μετά τη χειρουργική επέμβαση.",
		my_cataract_info2:
			"Έχουμε κάνει μεγάλη πρόοδο από τότε, και τώρα είναι δυνατόν να σας εμφυτεύσουν χειρουργικά έναν τεχνητό φακό για να αντικαταστήσει τον φυσικό φακό που αφαιρείται. Ο τεχνητός φακός ονομάζεται ενδοφθάλμιος φακός («IOL») και συμπεριφέρεται με τον ίδιο τρόπο όπως ο φυσικός φακός του οφθαλμού για να εστιάσει τις εικόνες στον αμφιβληστροειδή. Με ένα εμφύτευμα IOL, οι περισσότεροι ασθενείς θα χρειαστούν μόνο γυαλιά πρεσβυωπίας για κοντινή όραση (π.χ. ανάγνωση), ωστόσο το αποτέλεσμα θα εξαρτηθεί επίσης από τη γενική και οφθαλμική υγεία του ασθενούς. Ο οφθαλμίατρός σας θα συζητήσει τα πιθανά αποτελέσματα πριν από τη χειρουργική επέμβαση.",
		expected_lifetime_question:
			"Αναμενόμενη διάρκεια ζωής της συσκευής και τυχόν απαραίτητη παρακολούθηση",
		expected_lifetime_info1:
			"Ο IOL είναι ένα μόνιμο εμφύτευμα και επομένως προορίζεται να παραμείνει στο μάτι καθ’ όλη τη διάρκεια ζωής του ασθενούς, εκτός εάν χρειαστεί να αφαιρεθεί χειρουργικά για κάποιον άλλο λόγο.",
		expected_lifetime_info2:
			"Μετά την εμφύτευση του IOL, ο ασθενής συνήθως θα χρειαστεί να προσέλθει σε τουλάχιστον μία επίσκεψη παρακολούθησης με τον οφθαλμίατρό του/της για να λάβει μια έκθεση προόδου σχετικά με την επούλωση του οφθαλμού μετά τη χειρουργική επέμβαση. Σε περίπτωση έκτακτης ανάγκης μετά την εμφύτευση, συνιστάται στον ασθενή να επικοινωνήσει αμέσως με τον οφθαλμίατρο.",
		device_safety_question: "Ασφάλεια της συσκευής στον οφθαλμό του ασθενούς",
		device_safety_info:
			"Οι ενδοφθάλμιοι φακοί της της Lenstec έχουν ελεγχθεί ως προς τη βιοσυμβατότητα εντός του οφθαλμού καθώς και για τοξικότητα στον οφθαλμό και τους περιβάλλοντες ιστούς. Οι δοκιμές πραγματοποιήθηκαν σύμφωνα με διάφορα διεθνή πρότυπα και τα αποτελέσματα έδειξαν ότι τα υλικά του IOL δεν προκαλούν τοξικές, ερεθιστικές ή ευαισθητοποιητικές επιδράσεις στον οφθαλμό.",
		precautions_question:
			"Προειδοποιήσεις, προφυλάξεις ή μέτρα που πρέπει να λαμβάνονται από τον ασθενή",
		precautions_info:
			" οφθαλμίατρος που εκτελεί τη χειρουργική επέμβαση κανονικά θα παρέχει οδηγίες στον ασθενή πριν από τη χειρουργική επέμβαση. Άλλες προειδοποιήσεις και προφυλάξεις που συνιστώνται από τη Lenstec παρατίθενται παρακάτω.",
		before_surgery_title: "Πριν από τη χειρουργική επέμβαση​",
		before_surgery_info:
			"Μην τρίβετε τα μάτια μετά τη χορήγηση του αναισθητικού από τον γιατρό. Αυτό μπορεί να προκαλέσει γρατσουνιές στον κερατοειδή, οι οποίες θα περάσουν απαρατήρητες λόγω μουδιάσματος στον οφθαλμό.",
		after_surgery_title: "Μετά τη χειρουργική επέμβαση​",
		after_surgery_info: {
			after_surgery1:
				"Μην οδηγείται. Κανονίστε να σας παραλάβει κάποιος μετά τη χειρουργική επέμβαση.",
			after_surgery2:
				"Μην τρίβετε τα μάτια. Μπορεί να νιώθετε φαγούρα στα μάτια σας λίγες ημέρες μετά τη χειρουργική επέμβαση λόγω της επούλωσης.",
			after_surgery3:
				"Αποφύγετε τις σκονισμένες περιοχές. Αυτές μπορεί να ερεθίσουν το μάτι.",
			after_surgery4:
				"Μην εκθέτετε το μάτι σε νερό για λίγες ημέρες μετά τη χειρουργική επέμβαση. Αυτό περιλαμβάνει τη θάλασσα, πισίνες, μπανιέρες κ.λπ. Χρησιμοποιήστε μια ζεστή πετσέτα για να σκουπίσετε την περιοχή γύρω από το μάτι.",
			after_surgery5:
				"Μην εκτελείτε επίπονες δραστηριότητες. Αυτό περιλαμβάνει την κάμψη για την ανύψωση βαρέων αντικειμένων ή την έντονη γυμναστική.",
			after_surgery6: "Μην ασκείτε πίεση στο μάτι.",
		},
		placeholder: "Selected Language",
	},
	en: {
		learn_more: "Learn More",
		patient_site: "Lenstec Patient Information Website",
		lens_info: "Lens Information",
		patient_info: "Patient Information",
		flag: "/images/english.gif",
		cataract: "Cataract",
		image_reference: "Image Reference",
		intro:
			"The eye is made up of various structures that help us to see. The two main structures that focus images onto the retina (back of the eye), are the cornea (outer front surface of the eye) and the lens (within the eye). The diagram below shows the human eye and how we see ",
		cross_section_label: "Cross-Section Of Human Eye",
		diagram: "/images/eye-en.png",
		aging:
			"As we age, the eye's natural lens begins to harden and become cloudy. This can start happening from around the age of 40 or 50 years old. This hardened, cloudy lens is called a cataract, and it can cause a decrease in vision (e.g. blur, double vision) and make it difficult to perform everyday activities such as reading or driving.",
		my_cataract_question: "What Can Be Done About My Cataract?",
		my_cataract_info1:
			"The most popular treatment for cataract is surgery, to remove the cataract from the affected eye. In the past, cataract surgery only involved removing the cloudy lens, leaving the patient without a lens in the eye (termed “aphakic”). The patient would then have to wear very thick eyeglasses to compensate for poor vision after surgery.",
		my_cataract_info2:
			"We have come a long way since then, and now you can have an artificial lens surgically implanted to replace the natural one that is removed. The artificial lens is called an intraocular lens (“IOL”) and it behaves in the same way as the eye's natural lens to focus images on the retina. With an IOL implant, most patients will only need reading glasses for near vision (e.g. reading), however this outcome also depends on the patient's general and ocular health. Your ophthalmologist will discuss the possible outcomes before surgery.",
		expected_lifetime_question:
			"Expected Lifetime of the Device and Any Necessary Follow-up",
		expected_lifetime_info1:
			"The IOL is a permanent implant and therefore is intended to stay in the eye over the lifetime of the patient, unless it needs to be surgically removed for some other reason.",
		expected_lifetime_info2:
			"Once the IOL has been implanted, the patient is normally required to attend at least one follow-up visit with his/her ophthalmologist to obtain a progress report on the healing of the eye after surgery. Should any emergencies arise after implantation, the patient is advised to contact the ophthalmologist immediately.",
		device_safety_question: "Safety of the Device in the Patient's Eye",
		device_safety_info:
			"Lenstec's IOLs have been tested for biocompatibility within the eye and also for toxicity to the eye and its surrounding tissues. Testing was performed in accordance with various international standards and the results showed that the IOL materials do not pose any toxic, irritating or sensitizing effects to the eye.",
		precautions_question:
			"Warnings, precautions or measures to be taken by the patient",
		precautions_info:
			"The ophthalmologist performing the surgery will normally provide instructions to the patient before surgery. Other warnings and precautions recommended by Lenstec are listed below.",
		before_surgery_title: "Before Surgery",
		before_surgery_info:
			"Do not rub eyes after the doctor has put in anesthetic. This can cause scratches to the cornea which would go unnoticed due to numbness in the eye.",
		after_surgery_title: "After Surgery",
		after_surgery_info: {
			after_surgery1:
				"Do not drive. Arrange for someone to collect you after the surgery.",
			after_surgery2:
				"Do not rub eyes. Your eyes may feel itchy a few days after the surgery due to healing.",
			after_surgery3: "Avoid dusty areas. This can irritate the eye.",
			after_surgery4:
				"Do not expose the eye to water for a few days after the surgery. This includes the sea, pools, tubs, etc. Use a warm washcloth to wipe around the eye.",
			after_surgery5:
				"Do not perform strenuous activities. This includes bending down to lift heavy objects or undertaking rigorous exercise.",
			after_surgery6: "Do not put pressure on the eye.",
		},
		placeholder: "Selected Language",
	},
	es: {
		learn_more: "Más información",
		patient_site: "Página web de información para pacientes de Lenstec",
		lens_info: "Información sobre las lentes",
		patient_info: "Información para pacientes",
		flag: "/images/spain.gif",
		cataract: "Cataratas",
		image_reference: "Referencia de imagen",
		intro:
			"El ojo está compuesto por distintas estructuras que nos ayudan a ver. Las dos estructuras principales que enfocan las imágenes sobre la retina (parte posterior del ojo) son la córnea (la superficie externa frontal del ojo) y el cristalino (dentro del ojo). El diagrama a continuación muestra el ojo humano y cómo vemos ",
		cross_section_label: "Sección Transversal Del Ojo Human",
		diagram: "/images/eye-es.png",
		aging:
			"De A medida que envejecemos, el cristalino- la lente natural del ojo- comienza a endurecerse y se torna opaco. Este proceso puede comenzar alrededor de los 40 o 50 años. A este cristalino duro y opaco se lo denomina catarata. Puede provocar una disminución de la visión (visión doble o borrosa) y limitar actividades cotidianas tales como leer o conducir.",
		my_cataract_question: "¿Cómo se pueden tratar mis cataratas?",
		my_cataract_info1:
			"El tratamiento más frecuente para las cataratas es la cirugía mediante la cual se extrae la catarata del ojo afectado. En el pasado, la cirugía de cataratas se limitaba a extraer el cristalino opaco y así el paciente quedaba sin cristalino (es decir «afáquico») en el ojo. El paciente entonces tendría que usar gafas muy gruesas para compensar la visión deficiente tras la cirugía.",
		my_cataract_info2:
			"Se ha avanzado mucho desde entonces, y en la actualidad es posible, mediante una intervención quirúrgica, extraer el cristalino e implantar en su lugar una lente artificial. Esta lente artificial se denomina lente intraocular (LIO), y se comporta de la misma manera que la lente natural del ojo para enfocar imágenes sobre la retina. Cuando se implanta una LIO, la mayoría de los pacientes solo necesitarán gafas de lectura para ver a corta distancia (por ejemplo, para leer). Sin embargo, este resultado depende también de la salud ocular y general del paciente. Antes del procedimiento quirúrgico, su oftalmólogo/a le comentará los posibles resultados.",
		expected_lifetime_question:
			"Duración prevista de una lente y cuidados posteriores",
		expected_lifetime_info1:
			"Una lente intraocular es un implante permanente y, por lo tanto, está hecha para permanecer en el ojo durante toda la vida del paciente, a menos que sea necesario quitarla por algún otro motivo.",
		expected_lifetime_info2:
			"Después de habérsele implantado una lente, el paciente debe normalmente concurrir a su oftalmólogo/a al menos una vez para una consulta de control y así poder saber cómo avanza la cicatrización del ojo después de la cirugía. En caso de que surgiese una emergencia después de la cirugía, se le aconseja al paciente contactar con su oftalmólogo/a de inmediato.",
		device_safety_question: "La seguridad de la lente en el ojo del paciente",
		device_safety_info:
			"Las lentes intraoculares fabricadas por Lenstec han sido sometidas a pruebas de biocompatibilidad dentro del ojo y de toxicidad tanto para el ojo como para los tejidos a su alrededor. Dichas pruebas se realizaron de conformidad con distintas normas internacionales y los resultados obtenidos demostraron que los materiales de las LIO no provocan efectos tóxicos, irritantes o sensibilizantes para el ojo.",
		precautions_question:
			"Advertencias, precauciones y medidas que el paciente debe tener en cuenta",
		precautions_info:
			"El/la oftalmólogo/a cirujano/a será generalmente quien, antes del procedimiento quirúrgico, le indique al paciente lo que debe hacer. Encontrará a continuación más advertencias y precauciones que Lenstec recomienda.",
		before_surgery_title: "Antes de la cirugía",
		before_surgery_info:
			"No se frote el ojo después de que el/la médico/a colocó la anestesia. Puede rayar la córnea, lo que pasaría desapercibido debido a que el ojo está insensibilizado.",
		after_surgery_title: "Después de la cirugía",
		after_surgery_info: {
			after_surgery1:
				"No conduzca. Pídale a alguna persona que le lleve a casa después de la cirugía.",
			after_surgery2:
				"No se frote los ojos. Puede sentir picazón en los ojos hasta un par de días después de la cirugía debido al proceso de cicatrización.",
			after_surgery3:
				"Evite los ambientes con polvo. El polvo puede irritar el ojo.",
			after_surgery4:
				"No exponga el ojo al agua durante algunos días después de la cirugía. Evite el agua del mar, las piscinas, las bañeras, etc. Limpie alrededor del ojo con un paño tibio.",
			after_surgery5:
				"No realice actividades extenuantes. Esto incluye agacharse para levantar objetos pesados o hacer ejercicios exigentes.",
			after_surgery6: "No aplique presión sobre el ojo.",
		},
		placeholder: "Lingua selezionata",
	},
	fr: {
		learn_more: "En savoir plus",
		patient_site: "Site d’information pour les patients de Lenstec",
		lens_info: "Informations sur les lentilles",
		patient_info: "Informations pour les patients",
		flag: "/images/french.gif",
		cataract: "Cataracte",
		image_reference: "Référence des images",
		intro:
			"L'œil est composé de diverses structures qui nous permettent de voir. Les deux structures principales qui focalisent les images sur la rétine (arrière de l'œil) sont la cornée (surface avant externe de l'œil) et le cristallin (à l'intérieur de l'œil). Le diagramme ci-dessous décrit l'œil humain et comment nous voyons ",
		cross_section_label: "Coupe Transversale De L'œil Humain",
		diagram: "/images/eye-fr.png",
		aging:
			"En vieillissant, le cristallin naturel de l'œil commence à durcir et à devenir opaque. Cela peut commencer à se produire aux alentours des 40 / 50 ans. Cette opacification du cristallin, accompagné d'un durcissement de la lentille oculaire, s'appelle une cataracte. Cela peut entraîner une altération progressive de l'acuité visuelle (p. ex. flou, vision double) et rendre certaines activités du quotidien, telles que la lecture ou la conduite, plus difficiles.",
		my_cataract_question: "Que peut-on faire à propos de ma cataracte ?",
		my_cataract_info1:
			"Le traitement le plus courant de la cataracte est la chirurgie, qui consiste à retirer la cataracte de l'œil concerné. Auparavant, l'opération de la cataracte consistait uniquement à retirer le cristallin opacifié, laissant le patient sans cristallin dans l'œil (appelé « aphaque »). Le patient devait alors porter des lunettes très épaisses pour compenser la mauvaise vision résultant de la chirurgie.",
		my_cataract_info2:
			"Nous avons parcouru un long chemin depuis lors, et il est désormais possible de se faire implanter chirurgicalement un cristallin artificiel pour remplacer le cristallin naturel qui a été retiré. L'implant est appelé lentille intra-oculaire (« LIO ») et il se comporte de la même manière que le cristallin naturel de l'œil pour focaliser les images sur la rétine. Avec une LIO, la plupart des patients n'auront besoin de lunettes de lecture que pour la vision de près (par exemple pour lire), toutefois ce résultat dépend également de la santé générale et oculaire du patient. Votre ophtalmologiste évoquera avec vous l'intégralité des résultats potentiels avant l'opération.",
		expected_lifetime_question:
			"Durée de vie prévue de l'appareil et tout suivi post-opératoire nécessaire",
		expected_lifetime_info1:
			"La LIO est un implant permanent et est donc destinée à rester dans l'œil pendant toute la vie du patient, à moins qu'elle ne doive être retirée chirurgicalement pour une autre raison.",
		expected_lifetime_info2:
			"Une fois la LIO implantée, le patient doit se rendre au moins une fois chez son ophtalmologiste pour y effectuer un examen post-opératoire et voir l'évolution de la guérison. En cas d'urgence après l'implantation, le patient doit immédiatement contacter son ophtalmologiste.",
		device_safety_question: "Sécurité de l'appareil dans l'œil du patient",
		device_safety_info:
			"Les LIO de Lenstec ont été testées pour leur biocompatibilité dans l'œil et également pour leur toxicité pour l'œil et ses tissus environnants. Les tests ont été effectués conformément à diverses normes internationales et les résultats ont montré que les matériaux des LIO ne présentent aucun effet toxique, irritant ou sensibilisant pour l'œil.",
		precautions_question:
			"Avertissements, précautions ou mesures à prendre par le patient",
		precautions_info:
			"L'ophtalmologiste effectuant l'opération est tenu de fournir les instructions pertinentes au patient avant l'opération en question. Les autres mises en garde et précautions recommandées par Lenstec sont énumérées ci-dessous.",
		before_surgery_title: "Avant l'intervention",
		before_surgery_info:
			"Ne pas frotter les yeux après la pose de l'anesthésie par le docteur. Cela peut provoquer des rayures de la cornée qui passeraient inaperçues en raison de l'engourdissement de l'œil.",
		after_surgery_title: "Après l'opération",
		after_surgery_info: {
			after_surgery1:
				"Il est interdit de conduire. Vous devez demander à quelqu'un de venir vous chercher après l'opération.",
			after_surgery2:
				"Ne vous frottez pas les yeux. Vos yeux peuvent vous démanger quelques jours après l'opération, c'est un signe de guérison.",
			after_surgery3:
				"Évitez les zones poussiéreuses. Cela peut irriter les yeux.",
			after_surgery4:
				"Évitez d'exposer votre œil à l'eau pendant quelques jours après l'opération. Cela inclut la mer, la piscine, les bains, etc. Veillez à utiliser un gant de toilette chaud pour essuyer le contour des yeux.",
			after_surgery5:
				"Évitez les activités intenses. Cela inclut notamment le fait de se pencher pour soulever des objets lourds ou entreprendre des exercices rigoureux.",
			after_surgery6: "N'exercez pas de pression sur l'œil.",
		},
		placeholder: "Lingua selezionata",
	},
	it: {
		learn_more: "Per saperne di più",
		patient_site: "Sito web Lenstec con le informazioni per i pazienti",
		lens_info: "Informazioni sulle lenti",
		patient_info: "Informazioni per i pazienti",
		flag: "/images/italy.gif",
		cataract: "Cataratta",
		image_reference: "Riferimento immagine",
		intro:
			"L'occhio è formato da diverse strutture che ci aiutano a vedere. Le due strutture principali che mettono a fuoco l'immagine sulla retina (parte posteriore dell'occhio) sono la cornea (superficie anteriore esterna dell'occhio) e il cristallino (all'interno dell'occhio). Lo schema che segue mostra l'occhio umano e il modo in cui vediamo ",
		cross_section_label: "Sezione Trasversale Dell'occhio Uman",
		diagram: "/images/eye-it.png",
		aging:
			"Man mano che invecchiamo, il cristallino naturale dell'occhio inizia a indurirsi e diventa offuscato. Questo fenomeno può iniziare a verificarsi dai 40/50 anni di età. Questo cristallino indurito e offuscato è chiamato cataratta e può provocare una diminuzione nella visione (ad es. visione offuscata o doppia) e può rendere difficile svolgere le attività quotidiane come leggere o guidare.		",
		my_cataract_question: "Che cosa si può fare per la mia cataratta?",
		my_cataract_info1:
			"Il trattamento più popolare per la cataratta è la chirurgia, che rimuove la cataratta dall'occhio interessato. In passato la chirurgia della cataratta coinvolgeva solo la rimozione del cristallino offuscato e il paziente restava senza un cristallino nell'occhio (definito 'afachico'). Il paziente quindi era costretto a indossare occhiali molto spessi per compensare la visione scadente in seguito alla chirurgia.",
		my_cataract_info2:
			"Abbiamo fatto grossi passi avanti da quei tempi e adesso si può impiantare chirurgicamente un cristallino artificiale per sostituire quello naturale rimosso. Il cristallino artificiale è chiamato lente intraoculare (“IOL”) e si comporta allo stesso modo del cristallino naturale dell'occhio per mettere a fuoco le immagini sulla retina. Con un impianto di IOL, la maggior parte dei pazienti avrà bisogno solo degli occhiali da lettura per vedere da vicino (ad es. per leggere), tuttavia questo esito dipende anche dalla salute generale e oculare del paziente. Prima della chirurgia l'oculista parlerà con il paziente degli esiti possibili.",
		expected_lifetime_question:
			"Durata prevista del dispositivo e follow-up necessario",
		expected_lifetime_info1:
			"La IOL è un impianto permanente e pertanto è concepita per restare nell'occhio per tutta la vita del paziente, a meno che non debba essere rimossa chirurgicamente per qualche altro motivo.",
		expected_lifetime_info2:
			"Quando la IOL viene impiantata, il paziente di solito deve attendere almeno una visita di follow-up dall'oculista per ottenere un report sui progressi della guarigione dell'occhio dopo la chirurgia. Se si verificano emergenze dopo l'impianto, si consiglia al paziente di contattare immediatamente l'oculista.",
		device_safety_question:
			"Sicurezza del dispositivo nell'occhio del paziente",
		device_safety_info:
			"Le IOL di Lenstec sono state testate per la biocompatibilità nell'occhio e anche per la tossicità all'occhio e ai tessuti circostanti. I test sono stati eseguiti ai sensi di varie norme internazionali e i risultati hanno dimostrato che i materiali delle IOL non hanno alcun effetto tossico, irritante o sensibilizzante per l'occhio.",
		precautions_question:
			"Avvertenze, precauzioni o misure da intraprendere da parte del paziente ",
		precautions_info:
			" L'oculista che esegue l'intervento chirurgico normalmente offre le istruzioni necessarie al paziente prima di intervenire. Altre avvertenze e precauzioni raccomandate da Lenstec sono elencate di seguito.",
		before_surgery_title: "Prima dell'intervento chirurgico",
		before_surgery_info:
			"Non sfregarsi gli occhi dopo che il medico ha inserito l'anestetico. Questo potrebbe provocare graffi alla cornea il che potrebbe non essere notato a causa dell'insensibilità dell'occhio.",
		after_surgery_title: "Dopo l'intervento chirurgico",
		after_surgery_info: {
			after_surgery1:
				"Non guidare. Organizzarsi per farsi venire a prendere dopo l'intervento.",
			after_surgery2:
				"Non sfregarsi gli occhi. Si potrebbe avvertire prurito agli occhi per qualche giorno dopo l'intervento a causa del processo di guarigione.",
			after_surgery3:
				"Evitare aree polverose. La polvere può irritare gli occhi.",
			after_surgery4:
				"Non esporre l'occhio all'acqua per qualche giorno dopo l'intervento. Questo include mare, piscina, vasche, ecc. Usare un panno caldo per lavare la zona attorno agli occhi.",
			after_surgery5:
				"Non svolgere attività estreme. Sono incluse attività come piegarsi per sollevare oggetti pesanti o svolgere esercizi pesanti.",
			after_surgery6: "Non esercitare pressione sull'occhio.",
		},
		placeholder: "Lingua selezionata",
	},
	nl: {
		learn_more: "Meer informatie",
		patient_site: "Lenstec Website Patiëntgegevens",
		lens_info: "Lensinformatie",
		patient_info: "Patiëntgegevens",
		flag: "/images/netherlands.gif",
		cataract: "Cataract",
		image_reference: "Afbeeldingsreferentie",
		intro:
			"Het oog bestaat uit verschillende onderdelen waarmee we kunnen zien. De twee belangrijkste onderdelen waarmee afbeeldingen op het netvlies (achterkant van het oog) worden afgebeeld, zijn het hoornvlies (buitenste oppervlak van het oog) en de lens (in het oog). Op de onderstaande afbeelding van het menselijk oog wordt getoond hoe ons zicht werkt ",
		cross_section_label: "Dwarsdoorsnede Van Het Menselijk Oo",
		diagram: "/images/eye-nl.png",
		aging:
			"De natuurlijke lens van het oog wordt harder en vertroebelt naarmate we ouder worden. Dit begint rond de leeftijd van 40 of 50 jaar. Een harde, vertroebelde lens wordt een cataract genoemd en kan ervoor zorgen dat het zicht vermindert (bijv. wazig zicht of dubbelzien), waardoor dagelijkse bezigheden zoals lezen of autorijden lastiger worden.",
		my_cataract_question: "Wat kan ik doen om mijn cataract te verhelpen?",
		my_cataract_info1:
			"De meest gekozen behandeling van cataract is een operatie waarbij de cataract uit het betreffende oog wordt verwijderd. In het verleden werd tijdens een cataractoperatie alleen de vertroebelde lens verwijderd, waardoor de patiënt geen lens meer in zijn of haar oog had ('afakie' genoemd). Hierdoor moest de patiënt een bril met erg dikke glazen dragen vanwege slecht zicht als gevolg van de operatie.",
		my_cataract_info2:
			"Tegenwoordig is de behandeling verder ontwikkeld en is het mogelijk om een kunstlens te laten plaatsen ter vervanging van de natuurlijke, verwijderde lens. De kunstmatige lens wordt een intraoculaire lens (IOL) genoemd en projecteert beelden op het netvlies op precies dezelfde manier als de natuurlijk lens. In de meeste gevallen hebben patiënten met een IOL-implantaat alleen een leesbril nodig voor het dichtbij zien (bijv. lezen). Deze uitkomst is echter afhankelijk van de algehele gezondheid van de patiënt en de gezondheid van het oog. Uw oogarts bespreekt voorafgaand aan uw operatie de mogelijke uitkomsten.",
		expected_lifetime_question:
			"Verwachte levensduur van het instrument en vervolgbezoek",
		expected_lifetime_info1:
			"De IOL is een permanent implantaat en is daarom bedoeld om voor de rest van het leven van de patiënt in het oog te blijven, tenzij het implantaat om een bepaalde reden operatief moet worden verwijderd.",
		expected_lifetime_info2:
			"Zodra de IOL is geplaatst, moet de patiënt in de meeste gevallen ten minste één vervolgbezoek plannen met zijn of haar oogarts om een voortgangsrapportage op te stellen over het herstel van het oog. Indien er na de implantatie ernstige problemen optreden, wordt de patiënt verzocht om zo spoedig mogelijk contact op te nemen met de oogarts.",
		device_safety_question:
			"Veiligheid van het instrument in het oog van de patiënt",
		device_safety_info:
			"IOL's uit de van Lenstec zijn getest op biocompatibiliteit in het oog en zijn getest op giftigheid voor het oog en het omliggende weefsel. Deze tests zijn uitgevoerd in overeenstemming met verschillende internationale standaarden en de resultaten hebben aangetoond dat de materialen van de IOL geen giftig, irriterend of sensibiliserend effect hebben op het oog.",
		precautions_question:
			"Waarschuwingen en voorzorgsmaatregelen voor de patiënt ",
		precautions_info:
			"Doorgaans krijgen patiënten voorafgaand aan de operatie instructies van de oogarts die de operatie gaat uitvoeren. Hieronder vindt u overige waarschuwingen en voorzorgsmaatregelen die door Lenstec worden aangeraden.",
		before_surgery_title: "Voor de operatie",
		before_surgery_info:
			"Wrijf niet in uw ogen nadat de arts de verdoving heeft toegediend. Hierdoor kunnen er zonder dat u het door de verdoving in de gaten hebt, krassen op het hoornvlies ontstaan.",
		after_surgery_title: "Na de operatie",
		after_surgery_info: {
			after_surgery1:
				"Rijd geen auto. Zorg ervoor dat iemand u na afloop van de operatie komt ophalen.",
			after_surgery2:
				"Wrijf niet in uw ogen. Uw ogen kunnen tot enkele dagen na de operatie jeuken als gevolg van het genezingsproces.",
			after_surgery3:
				"Vermijd stoffige plekken. Dit kan irritatie aan het oog veroorzaken.",
			after_surgery4:
				"Zorg dat uw oog tot enkele dagen na de operatie niet in aanraking komt met water. Dit geldt voor de zee, zwembaden, badkuipen, etc. Gebruik een warm washandje om de huid rond uw ogen te wassen.",
			after_surgery5:
				"Voer geen inspannende activiteiten uit. Dit geldt voor bukken om zware objecten te tillen of het doen van zware trainingen.",
			after_surgery6: "Voer geen druk uit op uw oog.",
		},
		placeholder: "Lingua selezionata",
	},
	pl: {
		learn_more: "Dowiedz się więcej",
		patient_site:
			"Witryna internetowa firmy Lenstec zawierająca informacje dla pacjentów",
		lens_info: "Informacje dotyczące soczewek",
		patient_info: "Informacje dla pacjentów",
		flag: "/images/poland.gif",
		cataract: "Katarakta",
		image_reference: "Odniesienie do obrazu",
		intro:
			"Oko składa się z różnych struktur, które pozwalają nam widzieć. Dwie główne struktury, które skupiają obrazy na siatkówce (tył oka), to rogówka (zewnętrzna przednia powierzchnia oka) oraz soczewka (wewnątrz oka). Poniższy rysunek przedstawia ludzkie oko oraz to, jak widzimy ",
		cross_section_label: "Przekrój Poprzeczny Ludzkiego Ok",
		diagram: "/images/eye-pl.png",
		aging:
			"Z wiekiem naturalna soczewka oka zaczyna twardnieć i mętnieć. Proces ten może się rozpocząć około 40. lub 50. roku życia. Taką stwardniałą, mętną soczewkę nazywa się kataraktą - może ona osłabiać wzrok (powodować np. niewyraźne lub podwójne widzenie) i utrudniać wykonywanie codziennych czynności, takich jak czytanie lub prowadzenie pojazdów.",
		my_cataract_question: "Jak mogę leczyć kataraktę?",
		my_cataract_info1:
			"Najpopularniejszą metodą leczenia katarakty jest przeprowadzenie operacji w celu usunięcia jej z chorego oka. W przeszłości operacje katarakty obejmowały jedynie usunięcie zmętniałej soczewki, pozostawiając pacjenta bez soczewki w oku (określa się to mianem „afakii”). Pacjent musiał wówczas nosić okulary o bardzo grubych szkłach, co kompensowało słaby wzrok po operacji.",
		my_cataract_info2:
			"Od tego czasu przeszliśmy długą drogę i obecnie można chirurgicznie wszczepić do oka sztuczną soczewkę w celu zastąpienia usuniętej naturalnej. Taką sztuczną soczewkę oka nazywa się implantem soczewki wewnątrzgałkowej (IOL, ang. intraocular lens) i działa ona tak samo, jak naturalna soczewka oka, skupiając obrazy na siatkówce. Z implantem IOL większość pacjentów będzie potrzebowała jedynie okularów do widzenia z bliska (np. do czytania), jednak zależy to również od ogólnego stanu zdrowia pacjenta oraz zdrowia jego oczu. Lekarz okulista omówi z Państwem możliwe wyniki leczenia przed operacją.",
		expected_lifetime_question:
			"Przewidywana żywotność wyrobu oraz wszelkie konieczne wizyty kontrolne",
		expected_lifetime_info1:
			"Implant IOL jest wszczepiany na stałe, co oznacza, że jest przeznaczony do pozostania w oku pacjenta przez całe jego życie, o ile nie musi zostać usunięty chirurgicznie z innego powodu.",
		expected_lifetime_info2:
			"Po wszczepieniu implantu IOL pacjent jest standardowo zobowiązany do odbywania przynajmniej jednej wizyty kontrolnej u lekarza okulisty rocznie, aby uzyskać raport dotyczący postępów w gojeniu się oka po operacji. Jeśli po wszczepieniu implantu pojawią się jakiekolwiek problemy, pacjent powinien natychmiast zgłosić się do swojego okulisty.",
		device_safety_question: "Bezpieczeństwo dotyczące wyrobu w oku pacjenta",
		device_safety_info:
			"Implanty IOL firmy Lenstec zostały przebadane pod kątem biokompatybilności wewnątrz oka oraz toksyczności względem oka i tkanek go otaczających. Badania przeprowadzono zgodnie z różnymi międzynarodowymi normami, a wyniki wykazały, że materiały zawarte w implantach IOL nie mają toksycznego, drażniącego ani uczulającego wpływu na oko.",
		precautions_question:
			"Ostrzeżenia, środki ostrożności lub działania, które powinien podjąć pacjent",
		precautions_info:
			"Okulista prowadzący operację powinien przekazać pacjentowi instrukcje przed operacją. Pozostałe ostrzeżenia i środki ostrożności zalecane przez firmę Lenstec podano poniżej.",
		before_surgery_title: "Przed operacją",
		before_surgery_info:
			"Nie trzeć oczu po wprowadzeniu przez lekarza środka znieczulającego. Może to doprowadzić do zadrapania rogówki, co może zostać niezauważone z powodu odrętwienia oka.",
		after_surgery_title: "Po operacji",
		after_surgery_info: {
			after_surgery1:
				"Nie prowadzić pojazdów. Należy poprosić kogoś o odebranie po operacji.",
			after_surgery2:
				"Nie trzeć oczu. Gojenie się oczu może wywoływać ich swędzenie przez kilka dni po operacji.",
			after_surgery3: "Unikać zapylonych miejsc. Mogą one drażnić oko.",
			after_surgery4:
				"Nie wystawiać oka na działanie wody przez kilka dni po zabiegu. Dotyczy to morza, basenu, wanny itp. Do wycierania okolicy oka należy używać ciepłego ręcznika.",
			after_surgery5:
				"Zrezygnować z aktywności wymagających dużego wysiłku fizycznego. Dotyczy to schylania się w celu podnoszenia ciężkich przedmiotów lub wykonywania intensywnych ćwiczeń fizycznych.",
			after_surgery6: "Nie uciskać oka.",
		},
		placeholder: "Lingua selezionata",
	},
	pt: {
		learn_more: "Saber mais",
		patient_site: "Site de Informação para o Paciente Lenstec",
		lens_info: "Informação sobre a Lente",
		patient_info: "Informação sobre o Paciente",
		flag: "/images/portugal.png",
		cataract: "Cataratas",
		image_reference: "Referência de imagem",
		intro:
			"O olho é composto por várias estruturas que nos ajudam a ver. As duas principais estruturas que focam as imagens na retina (parte posterior do olho) são a córnea (superfície frontal exterior do olho) e a lente (no interior do olho). O diagrama abaixo apresenta o olho humano e a forma como vemos ",
		cross_section_label: "Corte Transversal Do Olho Human",
		diagram: "/images/eye-pt.png",
		aging:
			"À medida que envelhecemos, a lente natural dos olhos começa a endurecer e fica enevoada. Isto pode começar a acontecer por volta dos 40 ou 50 anos. Esta lente endurecida e enevoada chama-se uma catarata, e pode provocar uma diminuição da visão (ex. distorção, visão dupla) e dificultar a realização de atividades do dia a dia, tais como ler ou conduzir.",
		my_cataract_question: "O que pode ser feito em relação à minha catarata?",
		my_cataract_info1:
			"O tratamento mais popular para as cataratas é a cirurgia de remoção da catarata do olho afetado. No passado, a cirurgia de catarata apenas envolvia a remoção da lente enevoada, deixando o paciente sem uma lente no olho (designado por «afáquico»). O paciente teria então de usar óculos muito grossos para compensar a falta de visão após a cirurgia.",
		my_cataract_info2:
			"Evoluímos bastante desde então e, agora, é possível implantar cirurgicamente uma lente artificial para substituir a lente natural removida. A lente artificial chama-se lente intraocular («LIO») e comporta-se da mesma forma que a lente natural do olho para focar as imagens na retina. Com um implante LIO, a maioria dos pacientes apenas irá necessitar de óculos de leitura para a visão ao perto (ex. ler), contudo, este resultado também irá depender da saúde geral e ocular do paciente. O seu oftalmologista irá discutir os possíveis resultados antes da cirurgia.",
		expected_lifetime_question:
			"Vida útil expectável do dispositivo e qualquer seguimento necessário",
		expected_lifetime_info1:
			"A LIO é um implante permanente e, por conseguinte, destina-se a permanecer no olho durante a vida do paciente, salvo se tiver de ser removido cirurgicamente por outro motivo.",
		expected_lifetime_info2:
			"Uma vez implantada a LIO, normalmente solicita-se ao paciente que tenha, no mínimo, uma consulta de seguimento com o seu oftalmologista, no sentido de obter um relatório de progresso da cicatrização do olho após a cirurgia. Caso ocorra alguma emergência após o implante, o paciente deve contactar o oftalmologista imediatamente.",
		device_safety_question: "Segurança do dispositivo no olho do paciente",
		device_safety_info:
			"A LIO da Lenstec foi testada em termos de biocompatibilidade dentro do olho e também de toxicidade para o olho e tecidos circundantes. Os testes foram realizados em conformidade com várias normas internacionais, e o resultados demonstraram que os materiais da LIO não provocam quaisquer efeitos tóxicos, irritantes ou sensibilizantes para o olho.",
		precautions_question: "Aviso, precauções ou medidas a adotar pelo paciente",
		precautions_info:
			"Normalmente, o oftalmologista que a realiza a cirurgia irá fornecer instruções ao paciente antes da cirurgia. Os outros avisos e precauções recomendados pela Lenstec são apresentados abaixo.",
		before_surgery_title: "Antes da cirurgia",
		before_surgery_info:
			"Não esfregar os olhos após o médico ter aplicado o anestésico. Isto pode provocar arranhões na córnea que passariam despercebidos devido à insensibilidade no olho.",
		after_surgery_title: "Após a cirurgia",
		after_surgery_info: {
			after_surgery1:
				"Não conduzir. Peça a alguém para vir buscá-lo depois da cirurgia.",
			after_surgery2:
				"Não esfregar os olhos. Pode sentir comichão nos olhos durante alguns dias após a cirurgia devido à cicatrização.",
			after_surgery3: "Evitar áreas poeirentas. Isto pode irritar o olho.",
			after_surgery4:
				"Após a cirurgia, não expor o olho à água durante alguns dias. Isto inclui o mar, piscinas, banheiras, etc. Usar um pano morno para limpar à volta do olho.",
			after_surgery5:
				"Não realizar atividades extenuantes. Isto inclui dobrar-se para pegar em objetos pesados ou realizar exercícios exigentes.",
			after_surgery6: "Não colocar pressão sobre o olho.",
		},
		placeholder: "Lingua selezionata",
	},
	ro: {
		learn_more: "Aflați mai multe",
		patient_site: "Site-ul web de informații pentru pacienți Lenstec",
		lens_info: "Informații despre lentile",
		patient_info: "Informații pentru pacienți",
		flag: "/images/romania.png",
		cataract: "Cataractă",
		image_reference: "Referință de imagine",
		intro:
			"Ochiul este format din diverse structuri care ne ajută să vedem. Cele două structuri principale care focalizează imaginile pe retină (în spatele ochiului) sunt corneea (suprafața frontală exterioară a ochiului) și cristalinul (în interiorul ochiului). Diagrama de mai jos prezintă ochiul uman și cum vedem noi :",
		cross_section_label: "Secțiune transversală a ochiului uman",
		diagram: "/images/eye-ro.png",
		aging:
			"Pe măsură ce îmbătrânim, cristalinul natural al ochiului începe să se întărească și să se opacifieze. Acest lucru se poate întâmpla de la vârsta de 40 sau 50 de ani. Cristalinul întărit, opac se numește cataractă și poate provoca o scădere a acuității vizuale (de exemplu, vedere încețoșată, vedere dublă) și poate îngreuna derularea activităților de zi cu zi precum cititul sau șofatul. ",
		my_cataract_question: "Ce soluții există pentru tratamentul cataractei?",
		my_cataract_info1:
			"Cel mai popular tratament pentru cataractă este intervenția chirurgicală pentru îndepărtarea cataractei din ochiul afectat. În trecut, operația de cataractă presupunea doar îndepărtarea cristalinului opac, lăsând pacientul fără cristalin (termenul medical este „afachic”). Pacientul ar trebui apoi să poarte ochelari de vedere cu lentile foarte groase pentru a compensa vederea slabă după intervenția chirurgicală. ",
		my_cataract_info2:
			"Am parcurs un drum lung de atunci, iar acum cristalinul natural poate fi înlocuit cu unul artificial care se implantează chirurgical. Cristalinul artificial se numește lentilă intraoculară („LIO”) și se comportă la fel ca un cristalin natural, pentru a focaliza imaginile pe retină. După un implant LIO, majoritatea pacienților vor avea nevoie doar de ochelari de citit pentru vederea de aproape (de exemplu, pentru citit). Acest rezultat depinde totuși și de sănătatea generală și oculară a pacientului. Medicul oftalmolog va explica rezultatele posibile înainte de operație. ",
		expected_lifetime_question:
			"Durata de viață preconizată a dispozitivului și monitorizarea ulterioară necesară",
		expected_lifetime_info1:
			"LIO este un implant permanent și, prin urmare, este proiectată să rămână în ochi pe toată durata de viață a pacientului, cu excepția cazului în care trebuie îndepărtată chirurgical dintr-un alt motiv. ",
		expected_lifetime_info2:
			"După implantarea LIO, pacientul trebuie să se prezinte cel puțin la o vizită de control la medicul oftalmolog pentru a urmări vindecarea ochiului după intervenția chirurgicală. În cazul în care apar urgențe după implantare, pacientul este sfătuit să contacteze imediat medicul oftalmolog. ",
		device_safety_question: "Siguranța dispozitivului în ochiul pacientului",
		device_safety_info:
			"LIO-urile Lenstec au fost testate pentru biocompatibilitate în interiorul ochiului și, de asemenea, pentru toxicitate la nivelul ochiului și al țesuturilor adiacente. Testarea a fost efectuată în conformitate cu diverse standarde internaționale, iar rezultatele au arătat că materialele din LIO nu prezintă niciun efect toxic, iritant sau sensibilizant pentru ochi.",
		precautions_question:
			"Avertismente, precauții sau măsuri care trebuie luate de pacient ",
		precautions_info:
			"Oftalmologul care efectuează intervenția chirurgicală îi va oferi în mod normal pacientului instrucțiuni înainte de operație. Alte avertismente și precauții recomandate de Lenstec sunt enumerate mai jos.",
		before_surgery_title: "Înainte de operație ",
		before_surgery_info:
			"Nu vă frecați la ochi după ce medicul a aplicat anestezicul. Acest lucru poate provoca zgârieturi pe cornee care ar trece neobservate din cauza amorțelii ochiului. ",
		after_surgery_title: "După operație",
		after_surgery_info: {
			after_surgery1:
				"Nu conduceți vehicule. Rugați pe cineva să vă conducă acasă după operație.",
			after_surgery2:
				"Nu vă frecați la ochi. În timpul procesului de vindecare, puteți simți mâncărimi ale ochilor.",
			after_surgery3: "Evitați zonele cu praf. Praful poate irita ochiul.",
			after_surgery4:
				"Nu expuneți ochiul la apă timp de câteva zile după operație. Sunt incluse aici marea, piscinele, căzile etc. Utilizați un prosop înmuiat în apă caldă pentru a șterge zona în jurul ochiului.",
			after_surgery5:
				"Nu efectuați activități solicitante. Mai exact, nu vă aplecați pentru a ridica obiecte grele și nu faceți exerciții fizice dificile. ",
			after_surgery6: "Nu puneți presiune pe ochi. ",
		},
		placeholder: "Selected Language",
	},
	sk: {
		learn_more: "Zistiť viac",
		patient_site: "Webová lokalita s informáciami pre pacientov Lenstec",
		lens_info: "Informácie o šošovkách",
		patient_info: "Informácie pre pacienta",
		flag: "/images/slovakia.gif",
		cataract: "Katarakta",
		image_reference: "Odkaz na obrázok",
		intro:
			"Oko sa skladá z viacerých častí, ktoré nám pomáhajú vidieť. Dve hlavné časti, ktoré pomáhajú zaostriť obraz na sietnicu (zadnú stenu oka), sú rohovka (vonkajšia predná časť oka) a šošovka (v oku). Obrázok nižšie ukazuje ľudské oko a to, ako vidíme ",
		cross_section_label: "Priečny Rez Ľudským Oko",
		diagram: "/images/eye-sk.png",
		aging:
			"Vekom sa ľudská šošovka začne zakaľovať a tvrdnúť. Začne sa to diať okolo 40. alebo 50. roku života. Táto stvrdnutá, zakalená šošovka sa nazýva katarakta a spôsobuje zhoršenie videnia (napr. neostré, dvojité videnie). Uskutočňovanie každodenných aktivít ako čítanie alebo šoférovanie sa stáva ťažším.",
		my_cataract_question: "Čo sa dá robiť s mojou kataraktou?",
		my_cataract_info1:
			"Najbežnejšou liečbou je odstrániť kataraktu operatívne. V minulosti sa pri operácii katarakty len odstránila šošovka a pacient ostal bez nej (afakia). Následne musel pacient nosiť veľmi silné dioptrie na kompenzáciu slabého videnia po operácii.",
		my_cataract_info2:
			"Odvtedy nastal výrazný pokrok a dnes možno po odstránení prirodzenej ľudskej šošovky chirurgicky implantovať umelú. Umelá šošovka sa nazýva vnútroočná šošovka (IOL) a rovnako ako tá prirodzená zaostruje obraz na sietnicu. S IOL implantátom potrebuje väčšina pacientov iba okuliare do blízka (napr. na čítanie). Závisí to však aj od celkového a očného zdravia pacienta. Váš očný lekár s vami pred operáciou prediskutuje možný výsledok.",
		expected_lifetime_question:
			"Očakávaná životnosť zdravotníckej pomôcky a následné kontrolné prehliadky",
		expected_lifetime_info1:
			"IOL je trvácny implantát, preto je určený ostať v oku do konca života, ak nenastane dôvod chirurgicky ho odstrániť pre inú príčinu.",
		expected_lifetime_info2:
			"Po implantácii IOL sa má pacient dostaviť minimálne na jednu kontrolnú prehliadku, aby očný lekár vyhodnotil proces hojenia oka po operácii. V prípade vážnych problémov po implantácii má pacient okamžite kontaktovať svojho očného lekára.",
		device_safety_question: "Bezpečnosť pomôcky v oku pacienta",
		device_safety_info:
			"IOL výrobcu Lenstec bola testovaná na biokompatibilitu s okom a na toxicitu pre oko a okolité tkanivo. Testovanie sa uskutočnilo v súlade s rôznymi medzinárodnými normami a potvrdilo sa, že IOL materiály nepredstavujú pre oko žiadne toxické, dráždivé ani senzibilizujúce riziko.",
		precautions_question:
			"Upozornenia, varovania a bezpečnostné opatrenia určené pacientovi",
		precautions_info:
			"Očný chirurg, ktorý bude pacienta operovať, dá pacientovi pred operáciou potrebné pokyny. Nasledujú ďalšie upozornenia a varovania odporúčané spoločnosťou Lenstec.",
		before_surgery_title: "Pred operáciou",
		before_surgery_info:
			"Nechytajte si oči po aplikácii anestetika do nich. To by spôsobilo poškrabanie rohovky a nebolo by spozorované pre necitlivosť oka.",
		after_surgery_title: "​Po operácii",
		after_surgery_info: {
			after_surgery1: "Nešoférujte. Zabezpečte si odvoz domov po operácii.",
			after_surgery2:
				"Nepretierajte si oči. Vaše oči budú pár dní po operácii svrbieť, je to súčasťou procesu hojenia.",
			after_surgery3:
				"Vyhnite sa prašným priestorom. Mohlo by to podráždiť oči.",
			after_surgery4:
				"Pár dní po operácii nevystavujte oko kontaktu s vodou. Zahŕňa to more, bazén, vaňu a pod. Okolie oka si utrite teplou handričkou.",
			after_surgery5:
				"Nevykonávajte namáhavé aktivity. Zahŕňa to zohnutie sa a zdvihnutie ťažkého predmetu či namáhavé cvičenie.",
			after_surgery6: "Vyhýbajte sa činnostiam vytvárajúcim tlak v oku.",
		},
		placeholder: "Selected Language",
	},
});
