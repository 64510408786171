export const eu_countries = {
	AT: "Austria",
	BE: "Belgium",
	BG: "Bulgaria",
	CY: "Cyprus",
	CZ: "Czechia",
	DE: "Germany",
	DK: "Denmark",
	EE: "Estonia",
	ES: "Spain",
	FI: "Finland",
	FR: "France",
	GR: "Greece",
	HR: "Croatia",
	HU: "Hungary",
	IE: "Ireland",
	IT: "Italy",
	LT: "Lithuania",
	LU: "Luxembourg",
	LV: "Latvia",
	MT: "Malta",
	NL: "Netherlands",
	PO: "Poland",
	PT: "Portugal",
	RO: "Romania",
	SE: "Sweden",
	SI: "Slovenia",
	SK: "Slovakia",
};
