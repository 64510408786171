import React from "react";
import "../styles/Footer.css";
export default function Footer() {
	return (
		<footer>
			<div>
				<div>
					<p>WORLD HEADQUARTERS</p>
				</div>
				<div className="address">
					<p>LENSTEC, INC.</p>
					<p>1765 COMMERCE AVE. N.</p>
					<p>ST. PETERSBURG, FL 33716</p>
					<p>USA</p>
					<p>TEL: +1 727-571-2272</p>
					<p>FAX: +1 727-571-1792</p>
				</div>
				<div className="address" style={{ marginTop: "20px" }}>
					<p>U.S. CUSTOMER SERVICE:</p>
					<p>TEL: 1-866-536-7832</p>
					<p>FAX: 1-866-536-3040</p>
				</div>
			</div>
			<div>
				<div>
					<p>LENSTEC BARBADOS</p>
				</div>
				<div className="address">
					<p>LENSTEC (BARBADOS) INC.</p>
					<p>AIRPORT COMMERCIAL CENTRE</p>
					<p>PILGRIM ROAD</p>
					<p>CHRIST CHURCH, BARBADOS</p>
					<p>BB17092</p>
					<p>TEL: 246-420-6795</p>
					<p>FAX: 246-420-6797</p>
				</div>
				<div></div>
			</div>
			<div>
				<div>
					<p>LENSTEC UK</p>
				</div>
				<div className="address">
					<p>LENSTEC (BARBADOS) INC.</p>
					<p>LENSTEC HOUSE</p>
					<p>UNIT 8, MARINER HOUSE</p>
					<p>CALDER PARK</p>
					<p>WAKEFIELD</p>
					<p>WEST YORKSHIRE WF4 3FL</p>
					<p>ENGLAND</p>
					<p>TEL: +44 (0)1924 382 678</p>
					<p>FAX: +44 (0)1924 850 454</p>
				</div>
				<div></div>
			</div>
			<div>
				<div>
					<p>EUROPEAN REGIONAL OFFICE</p>
				</div>
				<div className="address">
					<p>TRENČIANSKA 47</p>
					<p>821 09 BRATISLAVA</p>
					<p>SLOVAK REPUBLIC</p>
					<p>TEL: 00421905798760</p>
				</div>
				<div></div>
			</div>
			{/* Indication */}
			<div className="indication">
				{/* <p>INDICATION </p>
				<p>
					The Lenstec Softec Aspheric Posterior Chamber Intraocular Lens is
					intended for the replacement of the human crystalline lens following
					phacoemulsification cataract removal in adults over the age of 21. The
					lens is indicated for capsular bag placement.
				</p>
				<p>
					CAUTION: Federal (U.S.) law restricts some of these devices to the
					sale by or on the order of a physician.
				</p>
				<p>
					Lenstec, Inc. is an ISO 13485 company manufacturing CE Marked Products
					with additional registrations of several countries.
				</p>
				<p>DUNS: 807554100</p> */}
				<p>© 2021 Lenstec, Inc.</p>
			</div>
		</footer>
	);
}
