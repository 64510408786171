import React, { useEffect, useState } from "react";
import "./Select.css";
export default function Select({
	patient_tokens,
	setTokens,
	tokens,
	lens_tokens,
	setLTokens,
	lTokens,
}) {
	var languages = [
		{ abbr: "en", name: "English" },
		{ abbr: "fr", name: "Français" },
		{ abbr: "es", name: "Español" },
		{ abbr: "it", name: "Italiano" },
		{ abbr: "de", name: "Deutsch" },
		{ abbr: "pt", name: "Português" },
		{ abbr: "nl", name: "Nederlandse" },
		{ abbr: "pl", name: "Polski" },
		{ abbr: "el", name: "Ελληνικά" },
		{ abbr: "cs", name: "Čeština" },
		{ abbr: "sk", name: "Slovensko" },
		{ abbr: "ro", name: "Română" },
	];
	function changeLanguage(e) {
		//console.log(e.target.value);
		patient_tokens.setLanguage(e.target.value);
		lens_tokens.setLanguage(e.target.value);
		//        updateSelected(languageCode);
		setTokens((tokens) => ({ ...patient_tokens }));
		setLTokens((lTokens) => ({ ...lens_tokens }));
		// console.log(patient_tokens._language);
		setSelectValue(e.target.value);
	}

	const [selectValue, setSelectValue] = useState(tokens._language);

	return (
		<select onChange={changeLanguage} value={selectValue}>
			{languages.map((language) => (
				<option
					className="select-items"
					key={language.abbr}
					value={language.abbr}
				>
					{language.name}
				</option>
			))}
		</select>
	);
}
