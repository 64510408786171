import React from "react";
// import "../styles/Models.css";
export default function LensRow({ lensFeatures, tokens }) {
	// tokens._props[`${tokens._language}`]; //gets array for the current language

	return (
		<div>
			{tokens.map((info) => (
				<div className="row" key={info.appearance}>
					{info.series && (
						<div className="series">
							<p>{info.series}</p>
						</div>
					)}
					<div className="series-models">
						<div className="model-names">
							{info.models.map((model) => (
								<p key={model}>{model}</p>
							))}
						</div>
						<div className="model-image">
							<img src={info.appearance} alt="" />
						</div>
						<div className="model-descriptions">
							{info.benefits.map((benefit, index) => (
								<p key={index}>{benefit}</p>
							))}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
